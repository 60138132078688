import * as React from "react"
import { Nav } from "../components/nav"
// markup

/* This example requires Tailwind CSS v2.0+ */
export default function VideosPage() {
  return (
    <div>
      <Nav/>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:px-8">
          <div className="text-center">
          <p className="mt-1 py-4 text-4xl text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">About</p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">GTTP was created to provide high quality instructional videos without distractions.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
